import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/Background.css';
import logoDirectoDelChef from './assets/directoDelChef-Red&Black.png';
import './assets/styles/publicWebsiteStyles.css'

function generateDots() {
  const dots = [];
  for (let i = 0; i < 100; i++) {
    const style = {
      top: `${Math.random() * 100}vh`,
      left: `${Math.random() * 100}vw`,
      animationDelay: `${Math.random() * 10}s`,
    };
    dots.push(<div key={i} className="dot" style={style}></div>);
  }
  return dots;
}

function App() {
  return (
    <>
      <div className="background-dots">
        {generateDots()}
      </div>
      <div className="container d-flex flex-column align-items-center justify-content-center vh-100">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4 text-center mx-auto">
            <img
              src={logoDirectoDelChef}
              alt="Imagen centrada"
              className="img-fluid centered-image"
            />
            <h1 className="mt-4">Próximamente</h1>
            <p>Estamos preparando algo increíble. Mantente atento.</p>
            {/* <p>
              Contactar a <a className='support-link' href="mailto:soporte@directodelchef.com">soporte</a>
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
